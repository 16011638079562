/* SarhERP Project Copyright © 2021 SarhSoft All rights reserved M.Farhat */

export default {
    tele_client_id: 'الشركة',
    identity: 'الهوية',
    mobile: 'رقم الجوال',
    mobile2: 'رقم الجوال2',
    contract_id: 'رقم العقد',
    account_number: 'رقم الحساب',
    client_account: 'رقم الحساب',
    contract_number: 'رقم العقد',
    service_number: 'رقم الخدمة',
    nationality_id: 'الجنسية',
    amount: 'المبلغ',
    debt_date: 'تاريخ المديونية',
    tele_client_type_id: 'نوع العميل',
    tele_service_type_id: 'نوع الخدمة',
    tele_claim_status_id: 'حالة المطالبة',
    tele_claim_status_parent_id: 'حالة المطالبة',
    notes: 'ملاحظات',
    assigned: 'مسندة',
    assigned_to: 'مسندة إلى',
    is_closed: 'is_closed',
    is_paid: 'is_paid',

};
