<script>
import AxiosSelect from '@base-inputs/AxiosSelect';
import Api from '@helpers/api';

export default {
  name: 'CarClientsSelect',
  extends: AxiosSelect,
  props: {
    clearable: {
      default: () => false
    },
    value: {},
    axiosUrl: {
      default() {
        // {user_id: this.$root.authUserId}
        return this.append && Object.keys(this.append).length ? ()=>Api.methods.resources.carClients(this.append) : Api.methods.resources.carClients;
      }
    },
    append: {
      type: Object,
      default: () => ({})
    },
  }
};
</script>
