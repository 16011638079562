/* SarhERP Project Copyright © 2021 SarhSoft All rights reserved M.Farhat */

export default {
      tele_claim_status_id: 'tele_claim_status_id',
    field_type_id: 'field_type_id',
    name_ar: 'name_ar',
    name_en: 'name_en',
    is_required: 'is_required',

};
