

<template>
  <app-tooltip :disabled="tooltipDisabled" :text="getTooltip">
    <v-btn v-bind="$attrs" v-on="$listeners" :class="getClasses" :min-width="minWidth" :style="cuStyles" fab small>
      <template v-if="create"><v-icon :style="cuStyles" :color="getColor('success')">mdi-plus</v-icon></template>
      <template v-if="update"><v-icon :style="cuStyles" :color="getColor('success')">mdi-pencil</v-icon></template>
      <template v-if="show"><v-icon :style="cuStyles" :color="getColor('primary')">mdi-eye</v-icon></template>
      <template v-if="assign_claim"><v-icon :style="cuStyles" :color="getColor('primary')">mdi-palette</v-icon></template>
      <template v-if="link_to_bill"><v-icon :style="cuStyles" :color="getColor('primary')">mdi-palette</v-icon></template>
      <template v-if="link_to_bill2"><v-icon :style="cuStyles" :color="getColor('primary')">mdi-palette</v-icon></template>
      <template v-if="upload"><v-icon :style="cuStyles" :color="getColor('primary')">mdi-file-document-multiple</v-icon></template>
      <template v-if="download"><v-icon :style="cuStyles" :color="getColor('primary')">mdi-arrow-down-bold-circle-outline</v-icon></template>
      <template v-if="showhistory"><v-icon :style="cuStyles" :color="getColor('primary')">mdi-post-outline</v-icon></template>
      <template v-if="approve"><v-icon :style="cuStyles" :color="getColor('primary')">mdi-check-decagram-outline</v-icon></template>
      <template v-if="show_notes"><v-icon :style="cuStyles" :color="getColor('primary')">mdi-square-rounded-badge</v-icon></template>
      <template v-if="claim_history"><v-icon :style="cuStyles" :color="getColor('primary')">mdi-post-outline</v-icon></template>
      <template v-if="unlink"><v-icon :style="cuStyles" :color="getColor('error')">mdi-comment-remove</v-icon></template>
      <template v-if="destroy"><v-icon :style="cuStyles" :color="getColor('error')">mdi-delete</v-icon></template>
      <template v-if="users"><v-icon :style="cuStyles" :color="getColor('primary')">mdi-account-multiple</v-icon></template>
      <slot></slot>
    </v-btn>
  </app-tooltip>
</template>

<script>

export default {
  name: 'Btn',
  props: {
    minWidth: {
      type: [String, Number],
      default: () => undefined
    },
    cuStyles: {
      type: [String],
      default: () => undefined
    },
    approve: {
      type: Boolean,
      default: () => false
    },
    update: {
      type: Boolean,
      default: () => false
    },
    create: {
      type: Boolean,
      default: () => false
    },
    assign_claim: {
      type: Boolean,
      default: () => false
    },
    link_to_bill: {
      type: Boolean,
      default: () => false
    },
    link_to_bill2: {
      type: Boolean,
      default: () => false
    },
    show: {
      type: Boolean,
      default: () => false
    },
    upload: {
      type: Boolean,
      default: () => false
    },
    unlink: {
      type: Boolean,
      default: () => false
    },
    download: {
      type: Boolean,
      default: () => false
    },
    showhistory: {
      type: Boolean,
      default: () => false
    },
    claim_history: {
      type: Boolean,
      default: () => false
    },
    show_notes: {
      type: Boolean,
      default: () => false
    },
    destroy: {
      type: Boolean,
      default: () => false
    },
    users: {
      type: Boolean,
      default: () => false
    },
    color: {
      default: () => undefined
    },
    tooltip: {
      default: () => undefined
    }
  },
  computed: {
    getClasses: () => [
      'app-dt-btn',
      'my-2'
    ].join(' '),
    getMinWidth() {
      return this.$attrs.icon === undefined ? this.minWidth : undefined;
    },
    getTooltip() {
      if(this.tooltip) return this.parseAttribute(this.tooltip);
      if(this.create) return this.$t('add');
      if(this.update) return this.$t('update');
      if(this.show) return this.$t('show');
      if(this.upload) return this.$t('files');
      if(this.download) return this.$t('download');
      if(this.showhistory) return this.$t('showhistory');
      if(this.approve) return this.$t('approval');
      if(this.show_notes) return this.$t('show_notes');
      if(this.claim_history) return this.$t('claim_history');
      if(this.assign_claim) return this.$t('assign');
      if(this.link_to_bill) return this.$t('link_to_bill');
      if(this.link_to_bill2) return this.$t('link_to_bill2');
      if(this.unlink) return this.$t('unlink');
      if(this.destroy) return this.$t('destroy');
      if(this.users) return this.$t('users');
      return null;
    },
    tooltipDisabled() {
      return this.getTooltip === null || this.getTooltip === undefined;
    }
  },
  methods: {
    getColor(fallbackValue = 'primary') {
      if(this.color) return this.color;

      return fallbackValue;
    }
  }
};
</script>
