import axios from 'axios';

const prefix = a => 'Panel/LegalParty' + (a ? `/${a}` : '');

export default {
  index(params, config = {}) {
    const url = prefix();
    return params === true ? url : axios.get(url, {...config, params});
  },
  store(data = {}, config = {}) {
    const url = prefix();
    return data === true ? url : axios.post(url, data, config);
  },
  update(id, data = {}, config = {}) {
    const url = prefix(id);
    return id === true ? url : axios.put(url, data, config);
  },
  show(id, config = {}) {
    const url = prefix(id);
    return id === true ? url : axios.get(url, config);
  },
  destroy(id, config = {}) {
    const url = prefix(id);
    return id === true ? url : axios.delete(url, config);
  }
};
