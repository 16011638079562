/* SarhERP Project Copyright © 2021 SarhSoft All rights reserved M.Farhat */

export default {
      user_id: 'user_id',
    tele_claim_id: 'tele_claim_id',
    assign_date: 'assign_date',
    assign_update: 'assign_update',
    assign_status: 'assign_status',
    tele_claim_status_id: 'tele_claim_status_id',
    tele_claim_status_parent_id: 'tele_claim_status_parent_id',

};
