/* SarhERP Project Copyright © 2021 SarhSoft All rights reserved M.Farhat */

export default {
      tele_client_id: 'tele_client_id',
    name_ar: 'name_ar',
    name_en: 'name_en',
    identity: 'identity',
    mobile: 'mobile',
    mobile2: 'mobile2',
    contract_number: 'contract_number',
    contract_id: 'contract_id',
    account_number: 'Account Number',
    client_account: 'Account Number',
    service_number: 'service_number',
    nationality_id: 'nationality_id',
    amount: 'amount',
    debt_date: 'debt_date',
    tele_client_type_id: 'tele_client_type_id',
    tele_service_type_id: 'tele_service_type_id',
    tele_claim_status_id: 'tele_claim_status_id',
    tele_claim_status_parent_id: 'tele_claim_status_parent_id',
    notes: 'notes',
    assigned: 'assigned',
    assigned_to: 'assigned_to',
    is_closed: 'is_closed',
    is_paid: 'is_paid',

};
