/* SarhERP Project Copyright © 2021 SarhSoft All rights reserved M.Farhat */

export default {
      tele_claim_id: 'tele_claim_id',
    tele_claim_status_id: 'tele_claim_status_id',
    tele_claim_status_row_id: 'tele_claim_status_row_id',
    tele_claim_status_field_id: 'tele_claim_status_field_id',
    field_value: 'field_value',

};
