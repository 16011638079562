/* SarhERP Project Copyright © 2021 SarhSoft All rights reserved M.Farhat */

export default {
      tele_claim_id: 'tele_claim_id',
    tele_claim_status_id: 'tele_claim_status_id',
    tele_claim_status_parent_id: 'tele_claim_status_parent_id',
    tele_claim_status_row_date: 'tele_claim_status_row_date',
    notes: 'notes',
    is_latest: 'is_latest',
    is_closed: 'is_closed',
    is_paid: 'is_paid',
    bill_id: 'bill_id',
    bill_number: 'bill_number',
    bill_year_id: 'bill_year_id',

};
