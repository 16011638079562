/* SarhERP Project Copyright © 2021 SarhSoft All rights reserved M.Farhat */

export default {
      user_id: 'user_id',
    tele_claim_id: 'tele_claim_id',
    collect_date: 'collect_date',
    collected_amount: 'collected_amount',
    collect_type: 'collect_type',

};
