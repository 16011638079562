

<script>
import AxiosSelect from '@base-inputs/AxiosSelect';
import Api from '@helpers/api';

export default {
  name: 'TeleClientsSelect',
  extends: AxiosSelect,
  props: {
    clearable: {
      default: () => false
    },
    value: {},
    axiosUrl: {
      default: () => Api.methods.resources.teleClients
    }
  }
};
</script>
