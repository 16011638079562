

<template>
  <app-col-span v-bind="$attrs">
    <ValidationProvider
        v-slot="v"
        :name="name"
        :rules="rules"
        :vid="vid"
    >
      <slot name="top"></slot>
      <v-switch
          v-model="val"
          :value="switchValue"
          v-bind="$attrs"
          v-on="$listeners"
          :error-count="v.errors.length"
          :error-messages="v.errors"
          :label="getLabel"
          class="mx-2"
      />
      <slot></slot>
      <slot name="bottom"></slot>
    </ValidationProvider>
  </app-col-span>
</template>

<script>
export default {
  name: 'Switcher',
  props: {
    value: {},
    vid: {},
    name: {
      required: true
    },
    switchValue: {
      default: () => undefined
    },
    rules: {
      default: () => ''
    },
    label: {
      type: String,
      default: () => undefined
    },
    statusNames: {
      type: Array,
      default: () => (['active', 'status'])
    },
    defaultValue: {
      default: () => undefined
    }
  },
  computed: {
    getLabel() {
      return this.parseAttribute(this.label || this.name);
    },
    val: {
      get() {
        return (this.value === undefined ? this.defaultValue : this.value);
      },
      set(v) {
        this.$emit('input', v);
      }
    }
  }
};
</script>
