

export default {
  name: 'الاسم',
  name_hint: 'حقل الاسم مطلوب',
  name_ar: 'الاسم العربي',
  name_ar_hint: 'حقل الاسم العربي مطلوب',
  name_en: 'الاسم الإنجليزي',
  name_en_hint: 'حقل الاسم الإنجليزي مطلوب',
  month: 'الشهر',
  month_hint: 'حقل الشهر مطلوب',
  login_id: 'اسم المستخدم',
  login_id_hint: 'حقل اسم المستخدم مطلوب',
  password: 'كلمة المرور',
  password_hint: 'حقل كلمة المرور مطلوب',
  password_confirmation: 'تأكيد كلمة المرور',
  password_confirmation_hint: 'حقل تأكيد كلمة المرور مطلوب',
  code: 'الكود',
  code_hint: 'حقل الكود مطلوب',
  erp_code: 'كود العميل بالمالية',
  erp_code_hint: 'حقل كود العميل بالمالية مطلوب',
  age: 'العمر',
  age_hint: 'حقل العمر مطلوب',
  birth_date: 'تاريخ الميلاد',
  birth_date_hint: 'حقل تاريخ الميلاد مطلوب',
  identity_id: 'رقم الهوية',
  identity_id_hint: 'رقم الهوية مطلوب مطلوب',
  identity: 'الهوية',
  identity_hint: 'حقل الهوية مطلوب مطلوب',
  insurance_company_id: 'شركة التأمين',
  insurance_company_id_hint: 'حقل شركة التأمين مطلوب',
  insurance_policy_id: 'رقم الوثيقة',
  insurance_policy_id_hint: 'رقم الوثيقة مطلوب',
  insurance_membership_id: 'رقم العضوية',
  insurance_membership_id_hint: 'رقم العضوية مطلوب',
  mobile: 'رقم الجوال',
  mobile_hint: 'رقم الجوال مطلوب',
  sort_order: 'ترتيب العرض',
  active: 'فعال',
  percent: 'النسبة',
  status: 'الحالة',
  status_label: 'فعال',
  other: 'غير ذلك',
  false: 'معطل',
  true: 'مفعل',
  accident_number: 'رقم الحادث',
  car_accident_number: 'رقم الحادث',
  accident_number_hint: 'حقل رقم الحادث مطلوب',
  consumer_id: 'المصاب',
  consumer_id_hint: 'حقل المصاب مطلوب',
  accident_date_no_type: 'تاريخ الحادث',
  accident_date: 'تاريخ الحادث م',
  accident_date_hint: 'حقل تاريخ الحادث الميلادي مطلوب',
  accident_date_hijri: 'تاريخ الحادث هـ',
  accident_date_hijri_hint: 'حقل تاريخ الحادث الهجري مطلوب',
  accident_type_id: 'نوع الحادث',
  accident_type_id_hint: 'حقل نوع الحادث مطلوب',
  car_accident_type_id: 'نوع الحادث',
  car_accident_type_id_hint: 'حقل نوع الحادث مطلوب',
  party_count: 'اطراف الحادث',
  party_count_hint: 'حقل عدد الأطراف مطلوب',
  region: 'المنطقة',
  region_hint: 'حقل المنطقة مطلوب',
  city_id: 'المدينة',
  city_id_hint: 'حقل المدينة مطلوب',
  assign_type: 'نوع الإسناد',
  assign_type_hint: 'حقل نوع الإسناد مطلوب',
  division_id: 'شعبة المرور',
  division_id_hint: 'حقل شعبة المرور مطلوب',
  car_division_id: 'شعبة المرور',
  car_division_id_hint: 'حقل شعبة المرور مطلوب',
  notes: 'ملاحظات',
  notes_hint: 'حقل ملاحظات مطلوب',
  claim_status1: 'حالة رئيسية',
  claim_status2: 'حالة فرعية',
  assign_types1: 'إسناد خاص',
  assign_types2: 'إسناد عام',
  consumer_type0: 'أساسي',
  consumer_type1: 'أب',
  consumer_type2: 'أم',
  consumer_type3: 'زوجة',
  consumer_type4: 'ابن',
  consumer_type5: 'بنت',
  medicalfiletype0: 'لا يوجد',
  medicalfiletype1: 'أصل',
  medicalfiletype2: 'صورة',
  registrationtype1: 'خاص',
  registrationtype2: 'نقل خاص',
  registrationtype3: 'نقل عام',
  registrationtype4: 'أجرة عام',
  medicalaccidentfiletype0: 'إلكتروني',
  medicalaccidentfiletype1: 'يدوي',
  medicalaccidentfiletype2: 'كروكي',
  invoivingtype0: 'غير محدد',
  invoivingtype1: 'مطالبات مفوترة',
  invoivingtype2: 'أقساط فاتورة واحدة',
  invoivingtype3: 'أقساط مفوترة',
  regionC: 'الوسطى',
  regionN: 'الشمالية',
  regionW: 'الغربية',
  regionE: 'الشرقية',
  regionS: 'الجنوبية',
  gender1: 'ذكر',
  gender2: 'أنثى',
  car_claim_status1: 'اجراء رئيسي',
  car_claim_status2: 'اجراء فرعي',
  menu_type1: 'قائمة رئيسية',
  menu_type2: 'قائمة فرعية',
  action_category1: 'إجراء طلبات',
  action_category2: 'إجراء دعاوى',
  action_ty1: 'عرض الطلبات',
  action_ty2: 'عرض الدعاوى',
  view_data_filtered: 'فرز العرض',
  parent_id: 'فرعي من:',
  parent_id_hint: 'حقل فرعي من مطلوب',
  legal_action_id: 'الإجراء',
  legal_action_id_hint: 'حقل الإجراء مطلوب',
  legal_action_parent_id: 'الإجراء الرئيسي',
  legal_action_parent_id_hint: 'حقل الإجراء الرئيسي مطلوب',
  legal_request_type_id: 'نوع المعاملة',
  legal_request_type_id_hint: 'حقل نوع المعاملة مطلوب',
  request_number: 'رقم المعاملة',
  request_number_hint: 'حقل رقم المعاملة مطلوب',
  request_date: 'تاريخ المعاملة م',
  request_date_hint: 'حقل تاريخ المعاملة م مطلوب',
  request_date_hijri: 'تاريخ المعاملة ه',
  request_date_hijri_hint: 'حقل تاريخ المعاملة ه مطلوب',
  claim_status_id: 'حالة المطالبة',
  claim_status_id_hint: 'حقل حالة المطالبة مطلوب',
  claim_status_parent_id: 'حالة المطالبة الرئيسية',
  claim_status_parent_id_hint: 'حقل حالة المطالبة الرئيسية مطلوب',
  field_type_id: 'نوع الحقل',
  field_type_id_hint: 'حقل نوع الحقل مطلوب',
  action_category: 'تصنيف الإجراء',
  action_category_hint: 'حقل تصنيف الإجراء مطلوب',
  legal_action_type: 'تصنيف الإجراء',
  legal_action_type_hint: 'حقل تصنيف الإجراء مطلوب',
  finance_sets: 'الحالة المالية',
  billing: 'المالية',
  claim_id: 'رقم المطالبة',
  claim_number: 'رقم المطالبة',
  claim_number_hint: 'حقل رقم المطالبة مطلوب',
  medical_claim_date: 'تاريخ الاستلام',
  claim_date: 'تاريخ استلام المطالبة',
  claim_date_hint: 'حقل تاريخ استلام المطالبة مطلوب',
  claim_date5: 'تاريخ التسجيل',
  claim_date5_hint: 'حقل تاريخ استلام المطالبة مطلوب',
  car_claim_date: 'تاريخ التسجيل',
  car_claim_date_hint: 'حقل تاريخ التسجيل مطلوب',
  policy_claim_date: 'تاريخ التسجيل',
  policy_claim_date_hint: 'حقل تاريخ التسجيل مطلوب',
  car_claim_date_hijri: 'تاريخ التسجيل هـ',
  car_claim_date_hijri_hint: 'حقل تاريخ التسجيل هـ مطلوب',
  claim_amount: 'قيمة المطالبة',
  no_assign_claim: 'تاريخ التسجيل',
  arrange_claim: 'نوع المطالبة',
  assigned: 'مسندة',
  claim_amount_hint: 'حقل قيمة المطالبة مطلوب',
  car_client_id_entry: 'اسم الشركة',
  car_client_id: 'العميل',
  car_client_id_hint: 'حقل العميل مطلوب',
  policy_client_id_entry: 'اسم الشركة',
  policy_client_id: 'العميل',
  policy_client_id_hint: 'حقل العميل مطلوب',
  invoice_number: 'رقم الفاتورة',
  invoice_number_hint: 'حقل رقم الفاتورة مطلوب',
  invoice_date: 'تاريخ الفاتورة',
  invoice_date_hint: 'حقل تاريخ الفاتورة مطلوب',
  hospital_id: 'المستشفى',
  hospital_id_hint: 'حقل المستشفى مطلوب',
  hospital_code: 'كود المستشفى',
  hospital_code_hint: 'كود المستشفى مطلوب',
  hospital_address: 'عنوان المستشفى',
  hospital_address_hint: 'عنوان المستشفى مطلوب',
  address: 'العنوان',
  address_hint: 'حقل العنوان مطلوب',
  hospital_file_id: 'رقم الملف',
  hospital_file_id_hint: 'حقل رقم الملف مطلوب',
  accident_id: 'رقم الحادث',
  accident_id_hint: 'حقل رقم الحادث مطلوب',
  medical_condition_id: 'الحالة الصحية',
  medical_condition_id_hint: 'حقل الحالة الصحية مطلوب',
  registration_type: 'نوع التسجيل',
  registration_type_hint: 'حقل نوع التسجيل مطلوب',
  year_id:'سنة المطالبة',
  year_id_hint: 'حقل سنة المطالبة مطلوب',
  provider_code:'رقم مزود الخدمة',
  provider_code_hint: 'حقل رقم مزود الخدمة مطلوب',
  original_amount:'المبلغ الأصلي',
  original_amount_hint:'حقل المبلغ الأصلي مطلوب',
  invoice_amount:'مبلغ الفاتورة',
  invoice_amount_hint:'حقل مبلغ الفاتورة مطلوب',
  final_amount:'المبلغ النهائي',
  final_amount_hint:'حقل المبلغ النهائي مطلوب',
  work_for:'جهة العمل',
  work_for_hint: 'حقل جهة العمل مطلوب',
  type:'النوع',
  type_hint:'حقل النوع مطلوب',
  digit_type:'نوع الخانة',
  digit_type_hint:'حقل نوع الخانة مطلوب',
  digit:'الخانة',
  digit_hint:'حقل الخانة مطلوب',
  digit_type1: 'حرف',
  digit_type2: 'رقم',
  consumer_name: 'اسم المصاب',
  consumer_name_hint: 'حقل اسم المصاب مطلوب',
  consumer_name_ar: 'اسم المصاب العربي',
  consumer_name_ar_hint: 'حقل اسم المصاب العربي مطلوب',
  consumer_name_en: 'اسم المصاب الإنجليزي',
  consumer_name_en_hint: 'حقل اسم المصاب الإنجليزي مطلوب',
  client_id: 'العميل',
  client_id_hint: 'حقل العميل مطلوب',
  party: 'الطرف',
  party_number: 'رقم الطرف',
  party_number_hint: 'حقل رقم الطرف مطلوب',
  vehicle_type_id: 'نوع المركبة',
  vehicle_type_id_hint: 'حقل نوع المركبة مطلوب',
  vehicle_model: 'موديل المركبة',
  vehicle_model_id: 'موديل المركبة',
  vehicle_model_id_hint: 'حقل موديل المركبة مطلوب',
  vehicle_serial_number: 'الرقم التسلسلي',
  vehicle_serial_number_hint: 'حقل الرقم التسلسلي مطلوب',
  vehicle_chassie_number: 'رقم الهيكل',
  vehicle_chassie_number_hint: 'حقل رقم الهيكل مطلوب',
  insurance_policy: 'وثيقة التأمين',
  insurance_policy_hint: 'حقل وثيقة التأمين مطلوب',
  insurance_expiration_date: 'انتهاء الوثيقة',
  insurance_expiration_date_hint: 'حقل انتهاء الوثيقة مطلوب',
  insurance_expiration_date_hijri: 'انتهاء وثيقة التأمين',
  insurance_expiration_date_hint_hijri: 'حقل انتهاء وثيقة التأمين ه مطلوب',
  tolerance_ratio: 'نسبة الخطأ',
  tolerance_ratio_hint: 'حقل نسبة الخطأ مطلوب',
  phone: 'رقم الهاتف',
  phone_hint: 'حقل رقم الهاتف مطلوب',
  email: 'البريد الإلكتروني',
  email_hint: 'حقل البريد الإلكتروني مطلوب',
  contact_name: 'اسم الشخص',
  contact_name_hint: 'حقل اسم الشخص مطلوب',
  contact_mobile: 'رقم الجوال',
  contact_mobile_hint: 'حقل رقم الجوال مطلوب',
  contact_mobile2: 'رقم الجوال2',
  contact_mobile2_hint: 'حقل رقم الجوال2 مطلوب',
  contact_fax: 'رقم الفاكس',
  contact_fax_hint: 'حقل رقم الفاكس مطلوب',
  contact_phone: 'رقم الهاتف',
  contact_phone_hint: 'حقل رقم الهاتف مطلوب',
  contact_email: 'البريد الإلكتروني',
  contact_email_hint: 'حقل البريد الإلكتروني مطلوب',
  consumer_work_id: 'جهة عمل المصاب',
  consumer_work_id_hint: 'حقل جهة عمل المصاب مطلوب',
  consumer_work_phone: 'رقم جهة العمل',
  consumer_work_phone_hint: 'حقل رقم جهة العمل مطلوب',
  plate_id_1: 'الحرف1',
  plate_id_1_hint: 'حقل الحرف1 مطلوب',
  plate_id_2: 'الحرف2',
  plate_id_2_hint: 'حقل الحرف2 مطلوب',
  plate_id_3: 'الحرف3',
  plate_id_3_hint: 'حقل الحرف3 مطلوب',
  plate_id_numbers: 'ارقام اللوحة',
  plate_id_numbers_hint: 'حقل ارقام اللوحة مطلوب',
  party_person_name: 'اسم المُرافق',
  party_name: 'اسم السائق',
  party_name_hint: 'حقل اسم السائق مطلوب',
  party_person_identity: 'هوية المُرافق',
  party_identity: 'هوية السائق',
  party_identity_hint: 'حقل هوية السائق مطلوب',
  party_type_id: 'نوع الطرف',
  party_type_id_hint: 'حقل نوع الطرف مطلوب',
  party_person_type_id: 'نوع المُرافق',
  party_person_type_id_hint: 'حقل نوع المُرافق مطلوب',
  is_consumer: 'هل المصاب داخل ھذه السیارة',
  is_consumer_driver: 'ھل المصاب ھو السائق',
  has_driver_license: 'السائق يملك رخصة قيادة',
  claim_type_id: 'نوع المطالبة',
  claim_type_id_hint: 'حقل نوع المطالبة مطلوب',
  status_date: 'تاريخ الحالة',
  status_date_hint: 'حقل تاريخ الحالة مطلوب',
  party_id: 'الطرف',
  party_id_hint: 'حقل الطرف مطلوب',
  menu_url: 'رابط القائمة',
  menu_url_hint: 'حقل رابط القائمة مطلوب',
  perm: 'صلاحيات القائمة',
  perm_hint: 'حقل صلاحيات القائمة مطلوب',
  icon: 'ايقونة القائمة',
  icon_hint: 'حقل ايقونة القائمة مطلوب',
  system_type: 'تتبع لنظام',
  system_type_hint: 'حقل تتبع لنظام مطلوب',
  model_name: 'تتبع لموديول',
  model_name_hint: 'حقل تتبع لموديول مطلوب',
  role_id: 'معرف المحموعة',
  role_id_hint: 'حقل معرف المحموعة مطلوب',
  permission_id: 'معرف الصلاحية',
  permission_id_hint: 'حقل معرف الصلاحية مطلوب',
  title: 'العنوان',
  title_hint: 'حقل العنوان مطلوب',
  status_type: 'نوع الحالة',
  status_type_hint: 'حقل نوع الحالة مطلوب',
  car_status_type: 'نوع الاجراء',
  car_status_type_hint: 'حقل نوع الاجراء مطلوب',
  policy_status_type: 'نوع الاجراء',
  policy_status_type_hint: 'حقل نوع الاجراء مطلوب',
  username: 'المستخدم',
  username_hint: 'حقل المستخدم مطلوب',
  car_claim_type_id: 'نوع المطالبة',
  car_claim_type_id_hint: 'حقل نوع المطالبة مطلوب',
  client_type_id: 'نوع العميل',
  client_type_id_hint: 'حقل نوع العميل مطلوب',
  tele_client_type_id: 'نوع العميل',
  tele_client_type_id_hint: 'حقل نوع العميل مطلوب',
  tele_client_type_id_to_string: 'نوع العميل',
  is_required: 'اجباري/مطلوب',
  is_required_hint: 'حقل (اجباري/مطلوب) مطلوب',
  present_date: 'تاريخ التقديم',
  paid_date: 'تاريخ التحصيل',
  paid_amount: 'المبلغ المحصل',
  total_amount: 'اجمالي المحصل',
  commission_percentage: 'نسبة العمولة',
  commission: 'العمولة',
  commission_amount: 'اجمالي العمولة',
  agency_id: 'رقم الوكالة',
  legal_case_id: 'القضية',
  legal_client_id: 'العميل-الموكل',
  legal_client_id_hint: 'حقل العميل-الموكل مطلوب',
  legal_client_type_id: 'نوع العميل-الموكل',
  legal_client_type_id_hint: 'حقل نوع العميل-الموكل مطلوب',
  legal_contract_type_id: 'نوع التعاقد',
  legal_contract_type_id_hint: 'حقل نوع التعاقد مطلوب',
  establishment_contract: 'عقد تأسيس',
  date_of_birth: 'تاريخ الميلاد',
  legal_case_type_id: 'نوع القضية',
  case_number: 'رقم القضية',
  case_date: 'تاريخ القضية',
  legal_case_status_id: 'حالة القضية',
  legal_litigation_degree_id: 'درجة التقاضي',
  identity_id_legal: 'رقم الهوية/السجل',
  fieldscount: 'الحقول',
  reason: 'سبب الاجراء',
  policy_claim_status_id: 'اجراء المطالبة',
  policy_claim_status_id_hint: 'حقل اجراء المطالبة مطلوب',
  policy_claim_status_parent_id: 'اجراء المطالبة الرئيسي',
  policy_claim_status_parent_id_hint: 'حقل اجراء المطالبة الرئيسي مطلوب',
  policy_claim_status_row_date: 'تاريخ الإجراء',
  car_claim_status_id: 'اجراء المطالبة',
  tele_claim_status_id: 'اجراء المطالبة',
  tele_assigned_to: 'مسندة الى',
  car_claim_status_id_hint: 'حقل اجراء المطالبة مطلوب',
  car_claim_status_parent_id: 'اجراء المطالبة الرئيسي',
  car_claim_status_parent_id_hint: 'حقل اجراء المطالبة الرئيسي مطلوب',
  car_claim_status_row_date: 'تاريخ الإجراء',
  car_consumer_id: 'المؤمن له',
  car_consumer_id_hint: 'حقل المؤمن له مطلوب',
  car_accident_destination_id: 'جهة الحادث',
  car_accident_destination_id_hint: 'حقل جهة الحادث مطلوب',
  car_accident_destination_type_id_entry: 'مباشر الحادث',
  car_accident_destination_type_id: 'نوع جهة الحادث',
  car_accident_destination_type_id_hint: 'حقل نوع جهة الحادث مطلوب',
  car_entry_action_id: 'إجراء مدخل البيانات',
  car_entry_action_id_hint: 'حقل إجراء مدخل البيانات مطلوب',
  car_entry_action_type_id: 'نوع إجراء المدخل',
  car_entry_action_type_id_hint: 'حقل نوع إجراء المدخل مطلوب',
  owner_name: 'اسم المالك',
  owner_name_hint: 'حقل اسم المالك مطلوب',
  owner_identity: 'هوية المالك',
  owner_identity_hint: 'حقل هوية المالك مطلوب',
  owner_mobile: 'جوال المالك',
  owner_mobile_hint: 'حقل جوال المالك مطلوب',
  owner_mobile2: 'جوال المالك2',
  owner_mobile2_hint: 'حقل جوال المالك2 مطلوب',
  driver_name: 'اسم السائق',
  driver_name_hint: 'حقل اسم السائق مطلوب',
  driver_identity: 'هوية السائق',
  driver_identity_hint: 'حقل هوية السائق مطلوب',
  driver_mobile: 'جوال السائق',
  driver_mobile_hint: 'حقل جوال السائق مطلوب',
  driver_mobile2: 'جوال السائق2',
  driver_mobile2_hint: 'حقل جوال السائق مطلوب2',
  user_name: 'اسم المستخدم',
  user_name_hint: 'حقل اسم المستخدم مطلوب',
  user_identity: 'هوية المستخدم',
  user_identity_hint: 'حقل هوية المستخدم مطلوب',
  user_mobile: 'جوال المستخدم',
  user_mobile_hint: 'حقل جوال المستخدم مطلوب',
  user_mobile2: 'جوال المستخدم2',
  user_mobile2_hint: 'حقل جوال المستخدم مطلوب2',
  user_vehicle_name: 'اسم المستخدم',
  user_vehicle_name_hint: 'حقل اسم المستخدم مطلوب',
  user_vehicle_identity: 'هوية المستخدم',
  user_vehicle_identity_hint: 'حقل هوية المستخدم مطلوب',
  user_vehicle_mobile: 'جوال المستخدم',
  user_vehicle_mobile_hint: 'حقل جوال المستخدم مطلوب',
  user_vehicle_mobile2: 'جوال المستخدم2',
  user_vehicle_mobile2_hint: 'حقل جوال المستخدم مطلوب2',
  car_vehicle_type_id: 'نوع المركبة',
  car_vehicle_type_id_hint: 'حقل نوع المركبة مطلوب',
  car_vehicle_model_id: 'موديل المركبة',
  car_vehicle_model_id_hint: 'حقل موديل المركبة مطلوب',
  car_vehicle_kind_id: 'طراز المركبة',
  car_vehicle_kind_id_hint: 'حقل طراز المركبة مطلوب',
  car_refund_reason_id: 'سبب الاسترداد',
  car_refund_reason_id_hint: 'حقل سبب الاسترداد مطلوب',
  car_accident_id: 'رقم الحادث',
  car_accident_id_hint: 'حقل رقم الحادث مطلوب',
  car_entry_notes: 'ملاحظة مدخل المطالبة',
  car_entry_notes_hint: 'حقل ملاحظة مدخل المطالبة مطلوب',
  target: 'المستهدف الشهري',
  target_hint: 'حقل المستهدف الشهري مطلوب',
  msgtext: 'نص الرسالة',
  msgtext_hint: 'حقل نص الرسالة مطلوب',
  sender_name: 'اسم المرسل',
  sender_name_hint: 'حقل اسم المرسل مطلوب',
  start_date: 'تاريخ بداية العقد',
  start_date_hint: 'حقل تاريخ بداية العقد مطلوب',
  terminte_date: 'تاريخ إلغاء العقد',
  terminte_date_hint: 'حقل تاريخ إلغاء العقد مطلوب',
  cusotmer_name: 'اسم عميل الشركة',
  cusotmer_name_hint: 'حقل اسم عميل الشركة مطلوب',
  cusotmer_name_ar: 'اسم عميل الشركة عربي',
  cusotmer_name_ar_hint: 'حقل اسم عميل الشركة عربي مطلوب',
  cusotmer_name_en: 'اسم عميل الشركة إنجليزي',
  cusotmer_name_en_hint: 'حقل اسم عميل الشركة إنجليزي مطلوب',
  policy_city_id: 'مدينة عميل الشركة',
  policy_city_id_hint: 'حقل مدينة عميل الشركة مطلوب',
  customer_owner_identity: 'هوية مالك عميل الشركة',
  customer_owner_identity_hint: 'حقل هوية مالك عميل الشركة مطلوب',
  broker_name: 'اسم الوسيط',
  broker_name_hint: 'حقل اسم الوسيط مطلوب',
  broker_mobile: 'جوال الوسيط',
  broker_mobile_hint: 'حقل جوال الوسيط مطلوب',
  contract_type_id: 'نوع العقد',
  contract_type_id_hint: 'حقل نوع العقد مطلوب',
  business_type_id: 'حجم الأعمال',
  business_type_id_hint: 'حقل حجم الأعمال مطلوب',
  police_client_id: 'شركة الوثائق/البوالص',
  police_client_id_hint: 'حقل شركة الوثائق/البوالص مطلوب',
  manager: 'اسم المدير',
  manager_hint: 'حقل اسم المدير مطلوب',
  iban: 'الأيبان',
  iban_hint: 'حقل الأيبان مطلوب',
  contact_person: 'الشخص المسؤول',
  contact_person_hint: 'حقل الشخص المسؤول مطلوب',
  contract_number: 'رقم العقد',
  contract_number_hint: 'حقل رقم العقد مطلوب',
  cr_id: 'رقم السجل',
  cr_id_hint: 'حقل رقم السجل مطلوب',
  sadad_id: 'رقم سداد',
  sadad_id_hint: 'حقل رقم سداد مطلوب',
  phone1: 'رقم الهاتف1',
  phone1_hint: 'حقل رقم الهاتف1 مطلوب',
  phone2: 'رقم الهاتف2',
  phone2_hint: 'حقل رقم الهاتف2 مطلوب',
  phone3: 'رقم الهاتف3',
  phone3_hint: 'حقل رقم الهاتف3 مطلوب',
  fax: 'رقم الفاكس',
  fax_hint: 'حقل رقم الفاكس مطلوب',
  parent_identity_id: 'هوية رب الأسرة',
  parent_identity_id_hint: 'حقل هوية رب الأسرة مطلوب',
  parent_name: 'اسم رب الأسرة',
  parent_name_hint: 'حقل اسم رب الأسرة مطلوب',
  consumer_type: 'تصنيف المصاب',
  consumer_type_hint: 'حقل تصنيف المصاب مطلوب',
  gender: 'الجنس',
  gender_hint: 'حقل الجنس مطلوب',
  payment_period: 'فترة الدفعة',
  payment_period_hint: 'حقل فترة الدفعة مطلوب',
  payment_reference: 'مرجع الدفعة',
  payment_reference_hint: 'حقل مرجع الدفعة مطلوب',
  hospital_enter_exit_date: 'تاريخ دخول/خروج المستشفى',
  hospital_enter_date: 'تاريخ دخول المستشفى',
  hospital_enter_date_hint: 'حقل تاريخ دخول المستشفى مطلوب',
  hospital_exit_date: 'تاريخ الخروج من المستشفى',
  hospital_exit_date_hint: 'حقل تاريخ الخروج من المستشفى مطلوب',
  medical_accident_destination_id: 'مباشر الحادث',
  medical_accident_destination_id_hint: 'حقل مباشر الحادث مطلوب',
  medical_source_hospital_id: 'المستشفى المحول منه',
  medical_source_hospital_id_hint: 'حقل المستشفى المحول منه مطلوب',
  medical_destination_hospital_id: 'المستشفى المحول له',
  medical_destination_hospital_id_hint: 'حقل المستشفى المحول له مطلوب',
  medical_discharge_condition_id: 'الحالة وقت الخروج',
  medical_discharge_condition_id_hint: 'حقل الحالة وقت الخروج مطلوب',
  claim_file: 'ملف المطالبة',
  claim_file_hint: 'حقل ملف المطالبة مطلوب',
  identitiy_photo: 'صورة الهوية',
  identitiy_photo_hint: 'حقل صورة الهوية مطلوب',
  invoice_file: 'ملف الفاتورة',
  invoice_file_hint: 'حقل ملف الفاتورة مطلوب',
  medical_report_file: 'التقرير الطبي',
  medical_report_file_hint: 'حقل التقرير الطبي مطلوب',
  insurance_card: 'كرت التأمين',
  insurance_card_hint: 'حقل كرت التأمين مطلوب',
  radioligy_photo: 'صور الأشعة',
  radioligy_photo_hint: 'حقل صور الأشعة مطلوب',
  transfered_from_another: 'تحويل من مستشفى آخر',
  transfered_from_another_hint: 'حقل تحويل من مستشفى آخر مطلوب',
  medical_accident_report_type: 'نوع تقرير الحادث',
  medical_accident_report_type_hint: 'حقل نوع تقرير الحادث مطلوب',
  officer_name: 'الضابط المحقق',
  officer_name_hint: 'حقل الضابط المحقق مطلوب',
  bill_number: 'رقم الفاتورة',
  bill_number_hint: 'حقل رقم الفاتورة مطلوب',
  bill_date: 'تاريخ الفاتورة',
  bill_date_hint: 'حقل تاريخ الفاتورة مطلوب',
  bill_year_id: 'سنة الفاتورة',
  bill_year_id_hint: 'حقل سنة الفاتورة مطلوب',
  invoicing_type: 'طريقة الفوترة',
  invoicing_type_hint: 'حقل طريقة الفوترة مطلوب',
  setting_type: 'نوع الإعداد',
  setting_category: 'القسم',
  setting_value: 'القيمة',
  setting_status: 'الحالة',
  message: 'الرسالة',
  'message:': 'الرسالة:',
  created_by: 'بواسطة',
  created_by_to_string: 'بواسطة',
  user_id: 'المستخدم',
  user_id_to_string: 'المستخدم',
  mod_user_id: 'المشرف',
  mod_user_id_to_string: 'المشرف',
  db_mod_user_id: 'المشرف',
  db_mod_user_id_to_string: 'المشرف',
  assistance_id_to_string: 'رقم المراسلة',
  assistance_created_at: 'تاريخ المراسلة',
  updated_by: 'تحديث بواسطة',
  assistance_id: 'رقم المراسلة',
  last_reply_by: 'اخر رد بواسطة',
  last_reply_by_to_string: 'اخر رد بواسطة',
  last_reply_at: 'اخر رد في',
  car_claim_type_id_to_string: 'نوع المطالبة',
  car_claim_id: 'رقم المطالبة',
  car_claim_id_to_string: 'رقم المطالبة',
  car_client_id_to_string: 'العميل',
  tele_claim_id: 'رقم الحساب/العقد',
  tele_claim_id_to_string: 'رقم الحساب/العقد',
  tele_client_id_to_string: 'العميل',
  is_mod: 'مشرف المراسلات',
}
