export default {
    methods: {
        nl2br(val) {
            return String(val).replaceAll("\n", "<br>");
        },
        hasValue(val, skipList = ['-', '0', '', null]) {
            if (Array.isArray(val)) {
                return val.every((v) => Boolean(v && !skipList.includes(v)));
            }

            return Boolean(val && !skipList.includes(val));
        },
        hasAnyValue(val, skipList = ['-', '0', '', null]) {
            if (Array.isArray(val)) {
                return val.some((v) => this.hasValue(v, skipList));
            }

            return this.hasValue(val, skipList);
        },
    }
}
